import * as React from "react";
import { Card } from "../types/scryfall";

type CardImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  card: Card;
  size?: "small" | "normal";
};

export const CardImage = ({
  card,
  size = "normal",
  ...props
}: CardImageProps) => {
  const style: React.CSSProperties = { ...props.style };
  style.width = size === "small" ? 120 : 400;
  style.height = (style.width / 488) * 680;

  const imageUris = (card.card_faces &&
    card.card_faces.map(face => face.image_uris)) || [card.image_uris];
  return (
    <div
      style={{
        width: style.width * imageUris.length,
        height: style.height,
        display: "flex"
      }}
    >
      {imageUris.map((imageUri, index) => (
        <img
          key={index}
          src={(imageUri || {}).normal}
          alt={card.name}
          {...props}
          style={style}
        />
      ))}
    </div>
  );
};
