import * as React from "react";
import { Set } from "../types/scryfall";

type SetIconProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  set: Set;
};

export const SetIcon = ({ set, ...props }: SetIconProps) => {
  return <img {...props} src={set.icon_svg_uri} alt={set.name} />;
};
