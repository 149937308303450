import React, { useState, FormEvent, Fragment } from "react";
import { Scryfall } from "./api/scryfall";
import { Cards, Collection, CollectionCard, Sets } from "./types/store";
import { Card } from "./types/scryfall";
import { CardImageIcon } from "./components/CardImageIcon";
import _ from "lodash";
import { SetIcon } from "./components/SetIcon";

type AppProps = {
  cards: Cards;
  collection: Collection;
  sets: Sets;
};

type SearchResult = Array<{
  id: string;
  scryfall: Card;
  owned: CollectionCard;
}>;

function App({ sets, cards, collection }: AppProps) {
  const [search, setSearch] = useState("");
  const [result, setResult] = useState<SearchResult>([]);
  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    Scryfall.searchCards(search).then(({ data }) => {
      const resultById = data.reduce<Cards>((map, card) => {
        map[card.oracle_id] = card;
        return map;
      }, {});
      const result: SearchResult = [];
      Object.keys(collection).forEach(id => {
        const scryfallCard = resultById[id];
        if (!scryfallCard) return;

        result.push({
          id,
          scryfall: scryfallCard,
          owned: collection[id]
        });
      });
      setResult(result);
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="search"
          placeholder="Search for a card"
          value={search}
          onChange={ev => setSearch(ev.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      {!!result.length && (
        <table>
          <thead>
            <tr>
              <th>Img</th>
              <th>Qty</th>
              <th colSpan={1}>Name</th>
            </tr>
          </thead>
          <tbody>
            {result.map(({ id, scryfall, owned }) => {
              return (
                <Fragment key={id}>
                  <tr style={{ borderTop: "1px solid white" }}>
                    <td rowSpan={1 + _.size(owned.variants)}>
                      <CardImageIcon
                        card={scryfall}
                        style={{ width: 26, height: 26 }}
                      />
                    </td>
                    <td>{owned.quantity}</td>
                    <td colSpan={1}>{scryfall.name}</td>
                  </tr>
                  {_.map(owned.variants, (variant, key) => {
                    const set = sets[variant.set_code];
                    return (
                      <tr key={`${id}${key}`} style={{ color: "#999" }}>
                        <td>{variant.quantity}</td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <SetIcon
                              set={set}
                              style={{ width: 18, marginRight: 10 }}
                            />
                            <span>
                              {set.name} ({set.code.toUpperCase()} #
                              {variant.collector_number})
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default App;
