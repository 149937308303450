// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

import React, { SetStateAction } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Scryfall } from "./api/scryfall";
import { Card } from "./types/scryfall";
import { Cards, Sets } from "./types/store";

const projectId = "magic-collection-7ced5";
function init() {
  const firebaseConfig = {
    apiKey: "AIzaSyD1euDtjO6JLM93_jFHIM4PjTtzoH1kuaA",
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: "821828957341",
    appId: "1:821828957341:web:932f8070bd9f3376"
  };
  firebase.initializeApp(firebaseConfig);

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      start(user);
    } else {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    }
  });
}

function start(user: firebase.User) {
  Promise.all([
    Scryfall.getAllSets().then(sets =>
      sets.reduce<Sets>((map, set) => {
        map[set.code] = set;
        return map;
      }, {})
    ),
    Scryfall.getAllCards().then(cards =>
      cards.reduce<Cards>((map, card) => {
        map[card.oracle_id] = card;
        return map;
      }, {})
    ),
    user.getIdToken().then(token => {
      const headers = new Headers();
      headers.set("Authorization", `Bearer ${token}`);
      return fetch(
        `https://europe-west1-${projectId}.cloudfunctions.net/collection`,
        {
          method: "GET",
          headers: headers
        }
      ).then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw new Error(`Error ${response.status}`);
        }
      });
    })
  ]).then(([sets, cards, collection]) => {
    ReactDOM.render(
      <App sets={sets} cards={cards} collection={collection} />,
      document.getElementById("root")
    );
  });
}

// function importCards() {
//   const db = firebase.firestore();
//   fetch("cards.json")
//     .then(res => res.json())
//     .then(async json => {
//       let count = 0;
//       let batch = db.batch();

//       const inc = async () => {
//         count++;
//         if (count >= 500) {
//           await batch.commit();
//           count = 0;
//           batch = db.batch();
//         }
//       };

//       for (let [key, { name, variants }] of Object.entries(json)) {
//         const doc = db.collection("cards").doc(key);
//         batch.set(doc, {
//           name,
//           quantity: 0
//         });
//         await inc();
//         for (let [vkey, vvalue] of Object.entries(variants)) {
//           batch.set(doc.collection("variants").doc(vkey), vvalue);
//           await inc();
//         }
//       }
//       if (count > 0) {
//         await batch.commit();
//       }
//     });
// }

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
