import * as React from "react";
import { Card } from "../types/scryfall";
import Tippy from "@tippy.js/react";
import { CardImage } from "./CardImage";

type CardImageIconProps = React.SVGAttributes<HTMLOrSVGElement> & {
  card: Card;
};

export const CardImageIcon = ({ card, ...props }: CardImageIconProps) => {
  return (
    <Tippy content={<CardImage card={card} size="small" />}>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -256 1930 1930"
      >
        <path
          fill="currentColor"
          d="M647.593 402.678q0 80-56 136t-136 56q-80 0-136-56t-56-136q0-80 56-136t136-56q80 0 136 56t56 136zm1024 384v448h-1408v-192l320-320 160 160 512-512zm96-704h-1600q-13 0-22.5 9.5t-9.5 22.5v1216q0 13 9.5 22.5t22.5 9.5h1600q13 0 22.5-9.5t9.5-22.5v-1216q0-13-9.5-22.5t-22.5-9.5zm160 32v1216q0 66-47 113t-113 47h-1600q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1600q66 0 113 47t47 113z"
        />
      </svg>
    </Tippy>
  );
};
