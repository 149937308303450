import Queue from "smart-request-balancer";
import queryString from "query-string";
import { Card, ScryfallList, BulkData, CardList, Set } from "../types/scryfall";

class ScryfallApi {
  queue = new Queue({
    rules: {
      default: {
        rate: 10, // 10 requests
        limit: 1, // per second
        priority: 1
      }
    }
  });

  lastRequest = null;

  getAllCards(): Promise<Card[]> {
    return this._fetch(`https://api.scryfall.com/bulk-data`)
      .then(result => {
        if (result.status >= 200 && result.status < 300) {
          return result.json();
        } else {
          throw new Error(`[${result.status}] ${result.statusText}`);
        }
      })
      .then((bulk: ScryfallList<BulkData>) => {
        const defaultCards = bulk.data.find(d => d.type === "default_cards");
        if (!defaultCards || !defaultCards.permalink_uri) {
          throw new Error(`Default cards not found`);
        }
        return this._fetch(defaultCards.permalink_uri);
      })
      .then(result => {
        if (result.status >= 200 && result.status < 300) {
          return result.json();
        } else {
          throw new Error(`[${result.status}] ${result.statusText}`);
        }
      });
  }

  getAllSets(): Promise<Set[]> {
    return this._fetch(`https://api.scryfall.com/sets`)
      .then(result => {
        if (result.status >= 200 && result.status < 300) {
          return result.json();
        } else {
          throw new Error(`[${result.status}] ${result.statusText}`);
        }
      })
      .then((sets: ScryfallList<Set>) => {
        return sets.data;
      });
  }

  searchCards(name: string): Promise<CardList> {
    return this._fetch(
      `https://api.scryfall.com/cards/search?${queryString.stringify({
        q: name,
        include_multilingual: true
      })}`
    ).then(result => {
      if (result.status >= 200 && result.status < 300) {
        return result.json();
      } else if (result.status === 404) {
        return {
          data: [],
          has_more: false,
          total_cards: 0
        };
      } else {
        throw new Error(`[${result.status}] ${result.statusText}`);
      }
    });
  }

  _fetch(url: string, options?: RequestInit) {
    return this.queue.request(
      retry =>
        fetch(url, options).then(result => {
          if (result.status === 429) {
            return retry(500);
          } else {
            return result;
          }
        }),
      "scryfall",
      "default"
    );
  }
}

export const Scryfall = new ScryfallApi();
